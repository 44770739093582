import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'crm-ui/lib';
import { connect } from '../../Store';

import {
  StyledInput,
  InputWrapper,
  ButtonWrapperSpaceBetween,
  IconWrapper,
  Label,
  Wrapper,
} from '../../ReusableComponents';
import { parseQuery } from '../../Utils';
import actions from '../../Store/actions';

const InputIcon = styled(Icon)`
  position: absolute;
  right: 20px;
`;

const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(8)};
`;

const Email = styled.div`
  color: #0f75bc;
`;

const ErrorLabel = styled.div`
  color: red;
  display: flex;
  margin-bottom: 4px;
`;

const CustomLink = styled.div`
  margin-left: ${rem(2)};
  color: #23231f;
  font-size: ${rem(12)};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const authorizeUrl = process.env.REACT_APP_AUTHORIZE;
const b2bUrl = process.env.REACT_APP_B2B_REDIRECT;
const b2bID = process.env.REACT_APP_B2B_ID;

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordError: false,
      loading: false,
      userName: '',
    };

    this.textInput = React.createRef();
    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    this.focus();

    const queries = parseQuery(this.props.history.location.search);

    if (queries && queries.error === 'true') {
      actions.setUserName(queries.user);

      this.setState({
        userName: queries.user,
        passwordError: true,
      });
    } else {
      this.setState({
        userName: this.props.userName,
      });
    }
  }

  onPasswordConfirm() {
    this.props.history.push('/app-space');
  }

  backToName() {
    if (this.props.user) {
      this.props.history.push('/app-space');
    } else {
      this.props.history.push('/');
    }
  }

  focus() {
    this.textInput.current.focus();
  }

  toRestorePassword() {
    this.props.history.push(
      `/password/restore?username=${this.state.userName}`,
    );
  }

  validateForm() {
    this.props.history.push('/password');

    let isValid = true;
    const { password } = this.state;

    if (password.length < 1) {
      isValid = false;
      this.setState({
        passwordError: true,
      });
    }

    return isValid;
  }

  render() {
    const { passwordError, password, loading, userName } = this.state;

    // const { userName } = this.sta;

    return (
      <Wrapper className="page">
        <form
          style={{ width: '100%' }}
          method="post"
          action={authorizeUrl}
          onSubmit={() => this.validateForm()}
        >
          <Label>{this.props.t('Vitajte')}</Label>
          <EmailWrapper>
            <IconWrapper>
              <Icon name="profile" color="#0f75bc" />
            </IconWrapper>
            <Email>{userName}</Email>
            <IconWrapper
              onClick={() => this.backToName()}
              style={{
                cursor: 'pointer',
              }}
            >
              <Icon name="close" />
            </IconWrapper>
          </EmailWrapper>

          {passwordError && (
            <ErrorLabel>{this.props.t('Nesprávne heslo')}</ErrorLabel>
          )}
          <InputWrapper>
            <StyledInput
              innerRef={this.textInput}
              name="password"
              error={passwordError}
              long
              placeholder={this.props.t('Zadajte heslo')}
              type="password"
              value={password}
              onChange={e =>
                this.setState({
                  password: e.target.value,
                })
              }
            />
            <InputIcon
              name="lock"
              color={passwordError ? 'red' : '#ccc'}
              size="l"
            />
          </InputWrapper>

          <ButtonWrapperSpaceBetween className="form-group">
            <CustomLink onClick={() => this.toRestorePassword()}>
              {this.props.t('Zabudli ste heslo?')}
            </CustomLink>
            <Button
              primary
              large
              type="submit"
              loading={loading}
              onClick={() => this.onPasswordConfirm()}
            >
              {this.props.t('Pokračovať')}
            </Button>
          </ButtonWrapperSpaceBetween>
          <input type="hidden" name="username" value={userName} />
          <input type="hidden" name="redirect_uri" value={b2bUrl} />
          <input type="hidden" name="app_id" value={b2bID} />
          <input type="hidden" name="scopes" value="test" />
        </form>
      </Wrapper>
    );
  }
}

Password.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Password.defaultProps = {
  userName: null,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};
export default withTranslation()(connect(mapStateToProps)(Password));
