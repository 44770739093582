import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { withTranslation } from 'react-i18next';
import { Wrapper } from '../../ReusableComponents';

const UnsubscribeText = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(10)};
  width: 100%;
`;

const MainPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  width: calc(100% - 20px);
  border-radius: ${rem(8)};
  padding: ${rem(10)};
  max-width: ${rem(450)};
  ${({ theme }) => theme.media.m`
  min-width: ${rem(400)};
  `};
`;

const Redirect = styled.a``;

class Unsubscribe extends React.Component {
  render() {
    return (
      <Wrapper className="page" style={{ background: 'white' }}>
        <MainPart>
          <UnsubscribeText>
            Boli ste odhlásený z odberu noviniek. Znovu sa príhlasiť je možné v
            sekcií: Nastavenia - Môj účet
          </UnsubscribeText>
          <Redirect
            href={`${process.env.REACT_APP_B2B_REDIRECT}/settings/account`}
          >
            {this.props.t('Prejsť na nastavenia')}
          </Redirect>
        </MainPart>
      </Wrapper>
    );
  }
}

Unsubscribe.propTypes = {
  t: PropTypes.func.isRequired,
};

Unsubscribe.contextTypes = {};

export default withTranslation()(Unsubscribe);
