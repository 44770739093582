export const parseHash = hash => {
  let content = hash;
  if (hash.indexOf('#') >= 0) {
    content = hash.substring(hash.indexOf('#') + 1);
  }
  const result = content.split('&').reduce((acum, item) => {
    const [key, value] = item.split('=');
    const r = Object.assign({}, acum);
    r[key] = value;
    return r;
  }, {});

  return result;
};

export const getTokenFromProps = props => {
  if (props.match.params.token) {
    return props.match.params.token;
  }
  return null;
};

export const parseQuery = query => {
  let q;
  if (query.startsWith('?')) {
    q = query.slice(1);
  } else {
    q = query;
  }
  const separated = q.split('&');
  const result = separated.reduce((acum, item) => {
    const splitted = item.split('=');
    if (!splitted.length || splitted.length !== 2) {
      return acum;
    }
    const [name, value] = splitted;
    return { ...acum, [name]: value };
  }, {});

  return result;
};
