import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { rem } from 'polished';
import { Button, Icon } from 'crm-ui/lib';
import { connect, actions } from '../../Store';
import { getTokenFromProps } from '../../Utils';
import API from '../../API';

import {
  StyledInput,
  InputWrapper,
  ButtonWrapperSpaceBetween,
  Wrapper,
} from '../../ReusableComponents';

const InputIcon = styled(Icon)`
  position: absolute;
  right: 20px;
`;

const ErrorInfo = styled.div`
  ${({ theme }) => theme.media.m`
  width: auto;
  `};
  padding: ${rem(20)};
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe6e7;
  border-radius: ${rem(7)};
  border: ${rem(1)} solid #e94141;
  font-size: ${rem(12)};
  margin-bottom: ${rem(10)};
`;

const SuccessInfo = styled.div`
  ${({ theme }) => theme.media.m`
width: auto;
`};
  padding: ${rem(20)};
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0ba14b4d;
  border-radius: ${rem(7)};
  border: ${rem(1)} solid #0ba14b;
  font-size: ${rem(12)};
  margin-bottom: ${rem(10)};
`;

const CustomLink = styled.div`
  margin-left: ${rem(2)};
  color: #23231f;
  font-size: ${rem(12)};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

class RestorePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordError: false,
      username: '',
      loading: false,
      success: false,
      password1: '',
      password: '',
    };
  }

  async componentDidMount() {
    const token = getTokenFromProps(this.props);

    this.setState({
      error: false,
    });

    if (token) {
      try {
        const result = await API.oauthB2bRestorePasswordInfo(token);

        actions.setUserName(result.username);

        this.setState({
          token,
          username: result.username,
        });
      } catch (e) {
        this.setState({
          error: this.props.t('Platnosť tokenu vypršala'),
        });
      }
    }
  }

  onInputChange(property, event) {
    if (property === 'password') {
      this.setState({
        password: event.target.value,
        passwordError: '',
        noSamePasswordError: undefined,
      });
    } else if (property === 'password1') {
      this.setState({
        password1: event.target.value,
        passwordError1: '',
        noSamePasswordError: undefined,
      });
    }
  }

  async onRestorePassword() {
    const { token, password } = this.state;

    if (this.checkPasswords()) {
      this.setState({
        loading: true,
        passwordError: undefined,
        passwordError1: undefined,
      });
      try {
        await API.oauthB2bRestorePassword(token, {
          data: { password },
        });

        this.setState({
          success: this.props.t('Heslo úspešne zmenené'),
          loading: false,
        });
      } catch (e) {
        this.setState({
          error: this.props.t('Platnosť tokenu vypršala'),
          loading: false,
        });
      }
    }
  }

  checkPasswords() {
    const { password, password1 } = this.state;
    let valid = true;

    if (password.length < 2) {
      valid = false;
      this.setState({
        passwordError: true,
      });
    }

    if (password1.length < 2) {
      valid = false;
      this.setState({
        passwordError1: true,
      });
    }

    if (password !== password1) {
      this.setState({
        passwordError: true,
        passwordError1: true,
        noSamePasswordError: true,
        loading: false,
      });
      valid = false;
    }

    return valid;
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.onRestorePassword();
    }
  }

  render() {
    const {
      passwordError,
      password,
      error,
      noSamePasswordError,
      passwordError1,
      password1,
      success,
      loading,
      username,
    } = this.state;

    return (
      <Wrapper className=" page" style={{ background: 'white' }}>
        <InputWrapper className="form-group">
          <StyledInput name="username" large long disabled value={username} />
          <InputIcon name="profile" color="#ccc" size="l" />
        </InputWrapper>

        {error && <ErrorInfo>{error}</ErrorInfo>}
        {noSamePasswordError && (
          <ErrorInfo>{this.props.t('Heslá sa nezhodujú')}</ErrorInfo>
        )}
        {success && <SuccessInfo>{success}</SuccessInfo>}

        {!error && !success && (
          <React.Fragment>
            <InputWrapper>
              <StyledInput
                name="password"
                error={passwordError}
                large
                long
                type="password"
                placeholder={this.props.t('Nové heslo')}
                value={password}
                onChange={e => this.onInputChange('password', e)}
              />
              <InputIcon name="lock" color="#ccc" size="l" />
            </InputWrapper>
            <InputWrapper className="form-group">
              <StyledInput
                name="password1"
                error={passwordError1}
                large
                long
                placeholder={this.props.t('Zopakujte heslo')}
                type="password"
                value={password1}
                onKeyDown={e => this.handleKeyDown(e)}
                onChange={e => this.onInputChange('password1', e)}
              />
              <InputIcon name="lock" color="#ccc" size="l" />
            </InputWrapper>
          </React.Fragment>
        )}
        <ButtonWrapperSpaceBetween className="form-group">
          <CustomLink
            onClick={() => {
              this.props.history.push('/password');
            }}
          >
            {this.props.t('Prejsť na prihlásanie')}
          </CustomLink>

          {!success ? (
            <Button
              primary
              large
              loading={loading}
              disabled={error}
              onClick={() => this.onRestorePassword()}
            >
              {this.props.t('Vytvoriť heslo')}
            </Button>
          ) : (
            <div />
          )}
        </ButtonWrapperSpaceBetween>
      </Wrapper>
    );
  }
}

RestorePassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};
export default withTranslation()(connect(mapStateToProps)(RestorePassword));
