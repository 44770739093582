import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background: white;
  padding: 8px;
  // height: 288px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  width: 100%; //or any percentage width you want
  ${({ theme }) => theme.media.m`
  width: 564px;
  padding: 20px;
`};
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 56px;
  border-radius: ${rem(2)};
  padding-left: ${rem(12)};
  border: 1px solid ${props => (props.error ? 'red' : '#DDDDDD')};
  font-size: ${rem(16)};
  ::placeholder {
    font-size: ${rem(16)};
  }
  :focus {
    outline: none !important;
    border: 1px solid ${props => (props.error ? 'red' : '#1d71b8')};
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${rem(10)};
  width: 100%;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${rem(24)};
`;

export const ButtonWrapperSpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${rem(24)};
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: ${rem(12)};
`;
