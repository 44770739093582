import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { Button, Icon } from 'crm-ui/lib';
import { withTranslation } from 'react-i18next';
import { connect, actions } from '../../Store';
import { parseQuery } from '../../Utils';

import API from '../../API';

import {
  StyledInput,
  InputWrapper,
  ButtonWrapperSpaceBetween,
  Label,
  Wrapper,
} from '../../ReusableComponents';

const InputIcon = styled(Icon)`
  position: absolute;
  right: 20px;
`;

const ErrorInfo = styled.div`
  padding: ${rem(20)};
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe6e7;
  border-radius: ${rem(7)};
  border: ${rem(1)} solid #e94141;
  font-size: ${rem(12)};
  margin-bottom: ${rem(10)};
`;

const CustomLink = styled.div`
  margin-left: ${rem(2)};
  color: #23231f;
  font-size: ${rem(12)};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

// const Info = styled.div`
//   width: calc(100% - 40px);
//   padding: ${rem(20)};
//   padding-top: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: #f4f9fd;
//   border-radius: ${rem(7)};
//   border: ${rem(1)} solid #d6e7f8;
//   font-size: ${rem(12)};
//   margin-bottom: ${rem(10)};
//   ${({ theme }) => theme.media.m`

//   padding: 20px;
// `};
// `;

const InfoText = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  text-align: start;
  margin-bottom: ${rem(12)};
`;

const SuccessInfo = styled.div`
  padding: ${rem(20)};
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0ba14b4d;
  border-radius: ${rem(7)};
  border: ${rem(1)} solid #0ba14b;
  font-size: ${rem(12)};
  margin-bottom: ${rem(10)};
`;

class RequestPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      nameDisabled: true,
      validUserName: false,
      userName: '',
    };
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
      nameDisabled: true,
      validUserName: false,
      userName: '',
    });
  }

  backToName() {
    this.props.history.push('/');
  }

  async componentDidMount() {
    if (this.props.userName) {
      this.setState({
        userName: this.props.userName,
      });
    } else {
      const queries = parseQuery(this.props.history.location.search);
      try {
        if (queries && queries.username) {
          await API.getUserByEmailAction({
            email: queries.username,
          });

          this.setState({
            userName: queries.username,
            validUserName: true,
          });
        } else {
          // this.props.history.push('/name');
          this.setState({
            nameDisabled: false,
          });
        }
      } catch (e) {
        // this.props.history.push('/name');
      }
    }
  }

  async sendRestorePassword() {
    this.setState({
      restoreSuccess: undefined,
      restorePasswordError: undefined,
      loading: true,
    });

    try {
      const result = await API.getUserByEmailAction({
        email: this.state.userName,
      });

      if (result.type === 'employee') {
        this.setState({
          restorePasswordError: this.props.t(
            'Zmena hesla zamestnanca nie je možná cez tento formulár',
          ),
          nameError: true,
          validUserName: false,
          loading: false,
        });

        return;
      }

      actions.setUserName(this.state.userName);
    } catch (e) {
      this.setState({
        restorePasswordError: this.props.t(
          'Používateľ s týmto prihlásovacím menom neexistuje',
        ),
        nameError: true,
        validUserName: false,
        loading: false,
      });

      return;
    }

    try {
      await API.oauthB2bRestorePasswordRequest({
        data: { username: this.state.userName },
      });
      this.setState({
        restoreSuccess: this.props.t(
          'Link na obnovu hesla bol zaslaný na Vašu emailovú adresu',
        ),
        sended: true,
        loading: false,
        validUserName: true,
      });
    } catch (e) {
      this.setState({
        restorePasswordError: this.props.t('Nepodarilo sa zaslať email'),
        loading: false,
        validUserName: false,
      });
    }
  }

  handleKeyDown(e, username) {
    if (e.key === 'Enter' && username.length) {
      this.sendRestorePassword();
    }
  }

  render() {
    const {
      error,
      restorePasswordError,
      restoreSuccess,
      loading,
      sended,
      nameDisabled,
      nameError,
      userName,
      validUserName,
    } = this.state;

    return (
      <Wrapper className="page">
        <Label>{this.props.t('Zabudnuté heslo')}</Label>

        {error && <ErrorInfo>{error}</ErrorInfo>}

        {!restorePasswordError && !restoreSuccess && (
          // <Info>
          //   <IconWrapper>
          //     <Icon name="info" color="#4f97e1" />
          //   </IconWrapper>
          <InfoText>
            {this.props.t(
              'Na Vašu emailovú adresu bude odoslaný odkaz na zmenu hesla',
            )}
          </InfoText>
          // </Info>
        )}

        <InputWrapper className="form-group">
          <StyledInput
            onKeyDown={e => this.handleKeyDown(e, userName)}
            name="username"
            large
            error={nameError}
            long
            placeholder={this.props.t('Vaše prihlasovacie meno')}
            disabled={nameDisabled || restoreSuccess}
            value={userName}
            onChange={e =>
              this.setState({
                userName: e.target.value,
                nameError: false,
              })
            }
          />
          <InputIcon name="profile" color="#ccc" size="l" />
        </InputWrapper>

        {restorePasswordError && <ErrorInfo>{restorePasswordError}</ErrorInfo>}
        {restoreSuccess && <SuccessInfo>{restoreSuccess}</SuccessInfo>}

        <ButtonWrapperSpaceBetween className="form-group">
          <CustomLink
            onClick={() => {
              if (validUserName) {
                actions.setUserName(this.state.userName);
                this.props.history.push('/password');
              } else {
                this.props.history.push('/name');
              }
            }}
          >
            {this.props.t('Prejsť na prihlásanie')}
          </CustomLink>

          <Button
            primary
            large
            loading={loading}
            disabled={sended || userName.length === 0}
            onClick={() => this.sendRestorePassword()}
          >
            {this.props.t('Odoslať')}
          </Button>
        </ButtonWrapperSpaceBetween>
      </Wrapper>
    );
  }
}

RequestPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

RequestPassword.defaultProps = {
  userName: null,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};
export default withTranslation()(connect(mapStateToProps)(RequestPassword));
