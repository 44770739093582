import cookie from 'react-cookies';
import API from '../API';

const cookieConfig = {
  crm: 'oxyrionAccessToken',
};

export default {
  setUserName: (user, newUserName) => ({
    userName: newUserName,
  }),

  setPageTitle: (user, newPageTitle) => ({
    pageTitle: newPageTitle,
  }),

  setUser: (user, newUser) => ({
    user: newUser,
  }),

  setLoggingOut: (loggingOut, value) => {
    return { loggingOut: value };
  },
  loggout: async () => {
    window.location.href = '/';
    const AccessToken = cookie.load(cookieConfig.crm);
    cookie.remove(cookieConfig.crm, { path: '/' });
    await API.oauthRevokeAction({ data: { access_token: AccessToken } });
  },
};
