import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, injectGlobal } from 'styled-components';
import { Theme } from 'crm-ui/lib';
import ErrorBoundery from './ErrorBoundory';
import StoreSynchorizer from './StoreSynchorizer';
import { Provider } from './Store';

import * as serviceWorker from './registerServiceWorker';

import TranslationLayer from './TranslationLayer';

injectGlobal`  
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundery>
      <ThemeProvider theme={Theme}>
        <StoreSynchorizer>
          <Provider>
            <TranslationLayer />
          </Provider>
        </StoreSynchorizer>
      </ThemeProvider>
    </ErrorBoundery>
  </React.StrictMode>,
  document.getElementById('root'),
);
serviceWorker.unregister();
