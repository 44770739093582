import React from 'react';
import styled from 'styled-components';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Button, Icon } from 'crm-ui/lib';
import { actions } from '../../Store';
import API from '../../API';
import {
  Wrapper,
  StyledInput,
  InputWrapper,
  ButtonWrapperSpaceBetween,
  Label,
} from '../../ReusableComponents';

const InputIcon = styled(Icon)`
  position: absolute;
  right: 20px;
`;

const ErrorLabel = styled.div`
  color: red;
  display: flex;
  margin-bottom: 4px;
`;

const CustomLink = styled.div`
  margin-left: ${rem(2)};
  color: #23231f;
  font-size: ${rem(12)};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const FirstTimeLogin = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  font-size: 12px;
  text-decoration: underline;
  color: #464444;
  cursor: pointer;
`;

class Name extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameError: false,
      login: '',
    };

    this.textInput = React.createRef();
    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    const login = cookie.load('oxyrionLastLogin');

    actions.setPageTitle('');

    this.focus();

    this.setState({
      login: login || '',
    });
  }

  focus() {
    this.textInput.current.focus();
  }

  async submitUserName() {
    const { login } = this.state;

    this.setState({
      loading: true,
    });

    try {
      const result = await API.getUserByEmailAction({
        email: login,
      });

      if (result.type === 'customer') {
        cookie.save('oxyrionLastLogin', result.email);
        actions.setUserName(result.email);
        this.props.history.push('./password');
      } else if (result.type === 'employee') {
        cookie.save('oxyrionLastLogin', result.email);
        window.location.href = process.env.REACT_APP_AUTHORIZE_CRM.replace(
          '<userName>',
          result.email,
        ).replace(
          '<redirectUri>',
          this.props.redirectUrl && this.props.redirectUrl.length > 0
            ? this.props.redirectUrl
            : '',
        );
      }
    } catch (e) {
      if (e.response.status === 404) {
        this.setState({
          nameError: this.props.t('Používateľ  neexistuje'),
          loading: false,
        });
      } else {
        this.setState({
          nameError: this.props.t('Pri overovaní používateľa nastala chyba'),
          loading: false,
        });
      }
    }
  }

  async submitFirstTimeLogin() {
    const { loading, login } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      cookie.save('oxyrionLastLogin', login);
      window.location.href = process.env.REACT_APP_AUTHORIZE_CRM.replace(
        '<userName>',
        login,
      ).replace('<redirect_uri>', '');
    } catch (e) {
      this.setState({
        nameError: this.props.t('Pri overovaní používateľa nastala chyba'),
        loading: false,
      });
    }
  }
  toRestorePassword() {
    this.props.history.push(`/password/restore`);
  }

  handleKeyDown(e, login) {
    if (e.key === 'Enter' && login.length) {
      this.submitUserName();
    }
  }

  render() {
    const { nameError, login, loading } = this.state;
    return (
      <Wrapper className="page">
        <Label>{this.props.t('Prihláste sa do systému Oxyrion')}</Label>
        {nameError && (
          <ErrorLabel>{this.props.t('Používateľ  neexistuje')}</ErrorLabel>
        )}
        <InputWrapper>
          <StyledInput
            onKeyDown={e => this.handleKeyDown(e, login)}
            innerRef={this.textInput}
            name="username"
            error={nameError}
            long
            type="email"
            placeholder={this.props.t('Prihlasovacie meno')}
            onChange={e =>
              this.setState({
                login: e.target.value,
                nameError: false,
              })
            }
            value={login}
          />
          <InputIcon
            name="profile"
            color={nameError ? 'red' : '#ccc'}
            size="l"
          />
        </InputWrapper>

        <ButtonWrapperSpaceBetween className="form-group">
          <CustomLink onClick={() => this.toRestorePassword()}>
            {this.props.t('Zabudli ste heslo?')}
          </CustomLink>
          <Button
            primary
            disabled={!login.length}
            loading={loading}
            onClick={() => this.submitUserName()}
          >
            {this.props.t('Pokračovať')}
          </Button>
        </ButtonWrapperSpaceBetween>
        {nameError && login && login.includes('dejmark.com') && (
          <FirstTimeLogin
            onClick={() => this.submitFirstTimeLogin()}
            title={this.props.t('Nový používateľ Oxyrion aplikácií')}
          >
            {this.props.t('Prihlasujem sa prvý krát')}
          </FirstTimeLogin>
        )}
      </Wrapper>
    );
  }
}

Name.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Name);
