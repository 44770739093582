import React, { Component } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { rem } from 'polished';
import { Loader } from 'crm-ui/lib';

import API from './API';

import App from './App';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(4)};
  padding-top: ${rem(12)};
  ${({ theme }) => theme.media.m`
  align-items: center;
  justify-content: center;
  padding: ${rem(0)};
  box-sizing: border-box;
  width: 100%; //or any percentage width you want
  `};
`;

API.setBaseUrl(process.env.REACT_APP_DOMAIN);

class TranslationLayer extends Component {
  state = {
    loading: true,
    langs: [],
  };

  async componentDidMount() {
    try {
      // const { items: translations } = await API.getTranslationsAction({
      //   app: 'LANDING_PAGE',
      // });

      // const { items: langs } = await API.getLanguagesAction({
      //   app: 'LANDING_PAGE',
      // });

      const resources = {};

      const translations = [];

      translations.forEach(t => {
        resources[t.lang] = {
          translation: t.translations,
        };
      });

      i18n
        .use(initReactI18next)
        .use(detector)
        .init({
          resources,
          fallbackLng: 'sk',
          keySeparator: false, // we do not use keys in form messages.welcome

          interpolation: {
            escapeValue: false, // react already safes from xss
          },
        });

      this.setState({
        loading: false,
        langs: [],
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const height = window.innerHeight;

    const { loading, langs } = this.state;

    if (loading) {
      return (
        <Wrapper style={{ height: `${height}px` }}>
          <Loader size="xxl" />
        </Wrapper>
      );
    }

    return (
      <I18nextProvider i18n={i18n}>
        <App langs={langs} />
      </I18nextProvider>
    );
  }
}

export default TranslationLayer;
