import React from 'react';
import PropTypes from 'prop-types';

class StoreSynchorizer extends React.Component {
  componentDidMount() {
    // actions.setYear(this.props.year);
  }
  render() {
    return this.props.children;
  }
}

StoreSynchorizer.propTypes = {
  children: PropTypes.element.isRequired,
  // year: PropTypes.number.isRequired,
};

export default StoreSynchorizer;
