import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { BrowserRouter } from 'react-router-dom';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { rem } from 'polished';
import { Loader, Icon } from 'crm-ui/lib';
import Router from './Routes';
import headerImage from './assets/images/oxyrion.png';
import API from './API';
import { parseHash } from './Utils';
import { actions, connect } from './Store';

const LoaderWrapper = styled.div`
  display: flex;
  margin: 29% 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(4)};
  padding-top: ${rem(12)};
  ${({ theme }) => theme.media.m`
  align-items: center;
  justify-content: center;
  padding: ${rem(0)};
  box-sizing: border-box;
  width: 100%; 
  `};
`;

const RouterWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.m`
  justify-content: center;
  align-items: center;
  `};
  border-radius: ${rem(8)};
  overflow: auto;
`;

const Header = styled.div`
  margin-bottom: ${rem(16)};
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.media.m`
  // position: absolute;
  margin-bottom: ${rem(50)};
  top: calc(50% - 350px);
  `};
`;

const Text = styled.div`
  font-size: 12px;
  text-align: center;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.media.m`
  position: absolute;
  font-size: 16px;
  top: calc(50% - 300px);
  width: 620px;
  margin-bottom: ${rem(0)}
  `};
`;

const HeaderImage = styled.img`
  max-width: ${rem(227)};
  ${({ theme }) => theme.media.m`

  max-width: ${rem(500)};
  `};
`;

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-top: ${rem(32)};
  ${({ theme }) => theme.media.m`
  width: 604px;
`};
`;

const LangSwitch = styled.div`
  color: #ccc;
  font-size: ${rem(12)};
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const LangsPopup = styled.div`
  width: ${rem(200)};
  position: absolute;
  top: ${rem(20)};
  background: white;
  border-radius: ${rem(4)};
`;

const LangRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: ${rem(12)};
  :hover {
    background: #e8e8e8;
  }
`;

const SelectedLang = styled.div``;

const IconWrapper = styled.div`
  margin-left: ${rem(4)};
  transform: ${props => (props.rotate ? 'rotate(-90deg)' : 'rotate(90deg)')};
`;

const StyledFooter = styled.div`
  color: #ccc;
  font-size: ${rem(12)};
`;

API.setBaseUrl(process.env.REACT_APP_DOMAIN);

const cookieConfig = {
  crm: 'oxyrionAccessToken',
  b2b: 'b2bAccessToken',
};

const b2bUrl = process.env.REACT_APP_B2B_REDIRECT;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLang: 'SK',
      showLangs: false,
      loading: true,
      userType: null,
      langs: [
        {
          value: 'SK',
          name: this.props.t('Slovenčina'),
        },
        {
          value: 'CZ',
          name: this.props.t('Čeština'),
        },
        {
          value: 'EN',
          name: this.props.t('Angličtina'),
        },
        {
          value: 'DE',
          name: this.props.t('Nemčina'),
        },
      ],
    };
  }

  async componentDidMount() {
    const query = parseHash(window.location.hash);

    if (query.error) {
      const login = cookie.load('oxyrionLastLogin');

      if (login && login !== '') {
        actions.setUserName(login);
        window.location.href = `${process.env.REACT_APP_LANDING_PAGE}/password?error=true&user=${login}`;
      }
      // alert(decodeURIComponent(query.error_description));
    }

    if (query.token && query.token !== '') {
      this.removeCookie();
      this.setCookie(query.token, query.expires_at, query.expires_in, 'crm');

      if (query.extraRedirect && query.extraRedirect.length > 0) {
        window.location.href = `${query.extraRedirect}#token=${query.token}`;
      } else {
        window.location.hash = '';
      }
    }
    let accessToken = this.getCookie('crm');

    let user = null;
    try {
      if (accessToken) {
        API.setToken(accessToken);
        user = await API.meAction();
        actions.setUser(user);
        actions.setUserName(user.principal_name);

        this.setState({
          userType: 'employee',
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      try {
        if (accessToken) {
          accessToken = this.getCookie('b2b');
          user = await API.b2bMeAction();
          actions.setUser(user);
          window.location.href = b2bUrl;

          this.setState({
            userType: 'customer',
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (e1) {
        this.setState({
          loading: false,
        });
      }
    }
  }

  setCookie(token, expirationDate = Date.now() + 3600, maxAge = 3600, name) {
    cookie.save(cookieConfig[name], token, {
      path: '/',
      expires: new Date(expirationDate),
      maxAge,
    });
  }

  getCookie(name) {
    return cookie.load(cookieConfig[name]);
  }

  getLoggingOutContainer = () => {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  };
  removeCookie() {
    cookie.remove(cookieConfig.name, {
      path: '/',
    });
  }

  render() {
    const height = window.innerHeight - 15;

    const { showLangs, loading, userType } = this.state;
    const { pageTitle, langs } = this.props;

    if (loading) {
      return (
        <Wrapper style={{ height: `${height}px` }}>
          <RouterWrapper>
            <Loader size="xxl" />
          </RouterWrapper>
        </Wrapper>
      );
    }

    return (
      <Wrapper
        style={{ height: `${height}px` }}
        onClick={() => {
          if (showLangs) {
            this.setState({
              showLangs: false,
            });
          }
        }}
      >
        <Header>
          <HeaderImage src={headerImage} />
        </Header>
        <Text>{this.props.t(pageTitle)}</Text>

        <RouterWrapper>
          <BrowserRouter>
            <Router userType={userType} />
          </BrowserRouter>
        </RouterWrapper>

        <FooterWrapper>
          <LangSwitch>
            <SelectedLang
              onClick={() =>
                this.setState({
                  showLangs: !showLangs,
                })
              }
            >
              {langs.find(l => l._id === this.state.selectedLang) &&
                this.props.t(
                  langs.find(l => l._id === this.state.selectedLang).lang_name,
                )}
            </SelectedLang>
            <IconWrapper
              rotate={showLangs}
              onClick={() =>
                this.setState({
                  showLangs: !showLangs,
                })
              }
            >
              <Icon name="arrow" size="s" color="#ccc" />
            </IconWrapper>
            {showLangs && (
              <LangsPopup>
                {langs.map(l => (
                  <LangRow
                    onClick={() => {
                      i18next.changeLanguage(l._id);

                      this.setState({
                        selectedLang: l._id,
                        showLangs: false,
                      });
                    }}
                  >
                    {this.props.t(l.lang_name)}
                  </LangRow>
                ))}
              </LangsPopup>
            )}
          </LangSwitch>
          <StyledFooter>
            {this.props.t(`Copyright © ${new Date().getFullYear()}`)}
          </StyledFooter>
        </FooterWrapper>
      </Wrapper>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
  langs: PropTypes.arrayOf({}),
};

App.defaultProps = {
  pageTitle: '',
  langs: [],
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};
export default withTranslation()(connect(mapStateToProps)(App));
